//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sync } from 'vuex-pathify'
import { ContentType, Language } from '@constants'
import { gql } from 'graphql-tag'

export default {
  name: 'HomePage',
  fetchOnServer: false,
  scrollToTop: true,
  data() {
    return {
      ContentType,
      Language
    }
  },
  computed: {
    filters: sync('filters'),
    currentBreakPoint() {
      return this.$store.get('app/currentBreakPoint') || 'md'
    },
    account() {
      return this.$store.get('user/user') || null
    },
    language() {
      return this.$store.get('language/locale') || 'en'
    },
    languageId() {
      return this.language === 'en' ? Language.ENGLISH : Language.DUTCH
    }
  },
  async mounted() {
    // this.$store.set('filters/options@countryId', null)
    // this.$store.set('filters/options@regionId', null)
    // this.$store.set('filters/options@contentTypeId', null)
    // this.$store.set('filters/options@contentCollectionId', null)
    this.setBreadcrumb({ isShow: true })
    this.setFilters({ isShow: true, showFilterTotal: true })

    if (typeof localStorage !== 'undefined') {
      const visitorId = localStorage.getItem('VisitorId')
      if (!visitorId) {
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation {
              createVisitor {
                id
              }
            }
          `
        })
        localStorage.setItem('VisitorId', data.createVisitor.id)
      }
    }
  }
}
